import Dropdown, {DropdownItemType, DropdownProps} from "./Dropdown";
import {useEffect, useState} from "react";
import {BaseResponseType} from "services/shared-models/sharedTypes";
import {BackendServices} from "services";
import MultiSelectDropdown, {MultiDropdownProps} from "./MultiSelectDropdown";
import {useCookieContext} from "contexts";

type LookupDropdownProps<T extends keyof typeof BackendServices> = {
    service: T;
    endpoint: keyof (typeof BackendServices)[T];
    onError?: (error: any) => void;
    textValueKey: string;
    idValueKey: string;
    query?: Record<string, any>;
    isPaginated?: boolean;
    disabled?: boolean;
    formProps?: any & { error?: string };
    changeEffect?: (value?: any) => void;
    multiselect?: boolean;
    error?: string;
    enableSearch?: boolean;
    labelClassName?: string;
    withOutMinWidth?: boolean;
    disableApi?: boolean;
    readOnly?: boolean;
    withAsterisk?: boolean;
    wrapperStyle?: React.CSSProperties;
    useReactSelect?: boolean;
    filter?: (item: any) => boolean;
    minWidth?: number;
} & (
    | ({ multiselect?: false } & Omit<DropdownProps, "data">)
    | ({ multiselect: true } & Omit<MultiDropdownProps, "data">)
    );

const access = (path: string, object: any) => path.split(".").reduce((o, i) => o[i], object);

/**
 * @param {LookupDropdownProps} props
 * @param {boolean} props.useReactSelect - If true, the component will use react-select instead of the default select element and formProps is ignored
 * @returns {React.FC}
 */
function LookupDropdown<T extends keyof typeof BackendServices>(props: LookupDropdownProps<T>) {
    const {
        endpoint,
        query,
        error,
        textValueKey,
        idValueKey,
        onError,
        service,
        isPaginated,
        disabled,
        multiselect,
        disableApi = false,
        wrapperStyle,
        filter,
        ...rest
    } = props;
    const [data, setData] = useState<DropdownItemType[]>([]);
    const {isAr} = useCookieContext();

    const queryParams = isPaginated ? {...query, pageIndex: 1, pageSize: 10} : query;

    useEffect(() => {
        if (disableApi || !endpoint || !service) return;

        (BackendServices[service][endpoint] as any)(queryParams)
            .then((res: BaseResponseType<any>) => {
                if (!res.hasError) {
                    const responseData = isPaginated ? res.data.result : res.data;
                    if (filter) {
                        setData(
                            responseData.filter(filter).map((item: any) => ({
                                id: access(idValueKey, item),
                                value: access(textValueKey, item),
                            })),
                        );
                    }else{
                        setData(
                            responseData.map((item: any) => ({
                                id: access(idValueKey, item),
                                value: access(textValueKey, item),
                            })),
                        );
                    }
                    
                    
                } else {
                    onError?.(res.description);
                }
            })
            .catch((error: any) => {
                onError?.(error);
            });
    }, [endpoint, onError, query, isAr]);

    return multiselect ? (
        <>
            <MultiSelectDropdown
                data={data}
                error={error}
                disabled={disabled}
                {...(rest as Omit<MultiDropdownProps, "data">)}
            />
        </>
    ) : (
        <>
            <Dropdown data={data} error={error} disabled={disabled} {...(rest as Omit<DropdownProps, "data">)} />
        </>
    );
}

export default LookupDropdown;
